exports.components = {
  "component---src-components-page-404-tsx": () => import("./../../../src/components/page/404.tsx" /* webpackChunkName: "component---src-components-page-404-tsx" */),
  "component---src-components-page-500-tsx": () => import("./../../../src/components/page/500.tsx" /* webpackChunkName: "component---src-components-page-500-tsx" */),
  "component---src-components-page-category-tsx": () => import("./../../../src/components/page/Category.tsx" /* webpackChunkName: "component---src-components-page-category-tsx" */),
  "component---src-components-page-dynamic-product-tsx": () => import("./../../../src/components/page/DynamicProduct.tsx" /* webpackChunkName: "component---src-components-page-dynamic-product-tsx" */),
  "component---src-components-page-home-flexible-tsx": () => import("./../../../src/components/page/HomeFlexible.tsx" /* webpackChunkName: "component---src-components-page-home-flexible-tsx" */),
  "component---src-components-page-publication-listing-tsx": () => import("./../../../src/components/page/PublicationListing.tsx" /* webpackChunkName: "component---src-components-page-publication-listing-tsx" */),
  "component---src-components-page-publication-tsx": () => import("./../../../src/components/page/Publication.tsx" /* webpackChunkName: "component---src-components-page-publication-tsx" */),
  "component---src-components-page-search-tsx": () => import("./../../../src/components/page/Search.tsx" /* webpackChunkName: "component---src-components-page-search-tsx" */),
  "component---src-components-page-splash-page-tsx": () => import("./../../../src/components/page/SplashPage.tsx" /* webpackChunkName: "component---src-components-page-splash-page-tsx" */),
  "component---src-components-page-store-locator-tsx": () => import("./../../../src/components/page/StoreLocator.tsx" /* webpackChunkName: "component---src-components-page-store-locator-tsx" */)
}

